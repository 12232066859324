// components
import { Icon } from 'components/Icon/Icon';

// utils
import { isEmpty } from 'utils/is-empty';
import { useTracking } from 'utils/hooks/useTracking';
import { buttonClick } from './trackingActions';
import { decodingContent } from 'utils/decodingContent';

export const BUTTON_TYPES = {
  DEFAULT: 'BUTTON_TYPE_DEFAULT',
  SEAMLESS: 'BUTTON_TYPE_SEAMLESS',
  LINK: 'BUTTON_TYPE_LINK',
  PLAIN: 'BUTTON_TYPE_PLAIN',
};

export const BUTTON_COLORS = {
  WHITE: 'BUTTON_COLOR_WHITE',
  BLACK: 'BUTTON_COLOR_BLACK',
  HIGHLIGHT: 'BUTTON_COLOR_HIGHLIGHT',
  PRIMARY: 'BUTTON_COLOR_PRIMARY',
  PRIMARY_DARK: 'BUTTON_COLOR_PRIMARY_DARK',
  GREY_BLUE: 'BUTTON_COLOR_GREY_BLUE',
  GREY_025: 'BUTTON_COLOR_GREY_025',
  GREY_050: 'BUTTON_COLOR_GREY_050',
  GREY_075: 'BUTTON_COLOR_GREY_075',
  GREY_100: 'BUTTON_COLOR_GREY_100',
  GREY_400: 'BUTTON_COLOR_GREY_400',
  GREY_650: 'BUTTON_COLOR_GREY_650',
};

export const BUTTON_BACKGROUND = {
  PRIMARY: 'BUTTON_BACKGROUND_PRIMARY',
  SECONDARY: 'BUTTON_BACKGROUND_SECONDARY',
  PRIMARY_LIGHT: 'BUTTON_BACKGROUND_PRIMARY_LIGHT',
  PRIMARY_DARK: 'BUTTON_BACKGROUND_PRIMARY_DARK',
  GREY_BLUE: 'BUTTON_BACKGROUND_GREY_BLUE',
  GREY_100: 'BUTTON_BACKGROUND_GREY_100',
  WHITE: 'BUTTON_BACKGROUND_WHITE',
  SUCCESS: 'BUTTON_BACKGROUND_GREEN',
};

export const BUTTON_ALIGNMENT = {
  DEFAULT: 'BUTTON_ALIGNMENT_DEFAULT',
  CENTER: 'BUTTON_ALIGNMENT_CENTER',
};

const ID_TO_CLASS = {
  BUTTON_TYPE_DEFAULT: '',
  BUTTON_TYPE_PLAIN: 'plain',
  BUTTON_TYPE_SEAMLESS: 'button--type-seamless',
  BUTTON_TYPE_LINK: 'button--type-link',
  BUTTON_COLOR_WHITE: 'button--white',
  BUTTON_COLOR_BLACK: 'button--black',
  BUTTON_COLOR_HIGHLIGHT: 'button--highlight',
  BUTTON_COLOR_PRIMARY: 'button--primary',
  BUTTON_COLOR_PRIMARY_LIGHT: 'button--primary-light',
  BUTTON_COLOR_PRIMARY_DARK: 'button--primary-dark',
  BUTTON_COLOR_GREY_BLUE: 'button--grey-blue',
  BUTTON_COLOR_GREY_025: 'button--grey-025',
  BUTTON_COLOR_GREY_050: 'button--grey-050',
  BUTTON_COLOR_GREY_075: 'button--grey-075',
  BUTTON_COLOR_GREY_100: 'button--grey-100',
  BUTTON_COLOR_GREY_400: 'button--grey-400',
  BUTTON_COLOR_GREY_650: 'button--grey-650',
  BUTTON_BACKGROUND_PRIMARY: 'button--background-primary',
  BUTTON_BACKGROUND_SECONDARY: 'button--background-secondary',
  BUTTON_BACKGROUND_PRIMARY_LIGHT: 'button--background-primary-light',
  BUTTON_BACKGROUND_PRIMARY_DARK: 'button--background-primary-dark',
  BUTTON_BACKGROUND_GREY_BLUE: 'button--background-grey-blue',
  BUTTON_BACKGROUND_WHITE: 'button--background-white',
  BUTTON_BACKGROUND_GREEN: 'button--background-positive-green',
  BUTTON_ALIGNMENT_DEFAULT: '',
  BUTTON_ALIGNMENT_CENTER: 'button--center',
};

interface ButtonProps extends Omit<React.ComponentProps<'button'>, 'type'> {
  children?: React.ReactNode | React.ReactNode[];
  color?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, ...args: any[]) => void;
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  type?: string;
  buttonRef?: React.Ref<HTMLButtonElement>;
  symbol?: string;
  background?: string;
  align?: string;
  buttonType?: 'button' | 'submit' | 'reset';
  className?: string;
  noTracking?: boolean;
}

export function Button({
  onClick,
  disabled = false,
  buttonRef,
  children,
  symbol,
  className,
  color,
  type = BUTTON_TYPES.DEFAULT,
  background = '',
  align = '',
  buttonType = 'button',
  noTracking = false,
  ...props
}: Readonly<ButtonProps>) {
  const track = useTracking();
  /**
   * builds a string of css classes to apply to our button element
   *
   * @return {string}
   */
  const getClasses = () => {
    const classes: string[] = [];
    if (ID_TO_CLASS[type] !== 'plain' || ID_TO_CLASS[type] === '') {
      classes.push('button');
    }

    if (color && typeof ID_TO_CLASS[color] !== 'undefined') {
      classes.push(ID_TO_CLASS[color]);
      if (disabled) {
        classes.push(`${ID_TO_CLASS[color]}--disabled`);
      }
    }

    if (typeof ID_TO_CLASS[type] !== 'undefined') {
      classes.push(ID_TO_CLASS[type]);
      if (disabled) {
        classes.push(`${ID_TO_CLASS[type]}--disabled`);
      }
    }

    if (typeof ID_TO_CLASS[background] !== 'undefined') {
      classes.push(ID_TO_CLASS[background]);

      if (disabled) {
        classes.push(`${ID_TO_CLASS[background]}--disabled`);
      }
    }

    if (typeof ID_TO_CLASS[align] !== 'undefined') {
      classes.push(ID_TO_CLASS[align]);
    }

    if (className) {
      classes.push(className);
    }

    return classes.join(' ');
  };

  const trackOnClick = (e, ...args) => {
    if (isEmpty(args) && typeof onClick === 'function') {
      onClick(e);
    } else if (typeof onClick === 'function') {
      onClick(e, ...args);
    }

    if (!isEmpty(e.currentTarget.innerText) && !noTracking) {
      track.trackEvent(buttonClick(e.currentTarget.innerText));
    }
  };

  return (
    <button
      className={getClasses()}
      onClick={trackOnClick}
      disabled={disabled}
      ref={buttonRef}
      type={buttonType}
      {...props}
    >
      {symbol && <Icon symbol={symbol} />}
      {typeof children !== 'string' ? children : decodingContent(children)}
    </button>
  );
}
