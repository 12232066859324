import { type PropsWithChildren } from 'react';
import { Typo } from '@geberit/gdds';

// components
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// styles
import styles from './headline.module.scss';

// utils
import { headlineSizes as sizes, Headline1 } from './';
import { decodingContent } from 'utils/decodingContent';
import { classNameBuilder as buildClassName } from 'utils/classNameBuilder';
import { useThemeName } from 'utils/hooks/use-theme';
import { THEME_NAMES } from 'themes';
import { useTheme } from 'styled-components';
import { isEmpty } from 'utils/is-empty';

export const Formats = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

export const FontWeights = {
  bold: 700,
  medium: 500,
  regular: 400,
  light: 300,
};

export interface HeadlineProps {
  format: (typeof Formats)[keyof typeof Formats];
  tag?: (typeof Formats)[keyof typeof Formats];
  title?: string;
  subtitle?: string;
  titleFontWeight?: (typeof FontWeights)[keyof typeof FontWeights];
  subtitleFontWeight?: number;
  isUppercase?: boolean;
  className?: string;
  titlePreviewId?: string;
  subtitlePreviewId?: string;
}

export const Headline = ({
  title,
  subtitle,
  format,
  tag,
  titleFontWeight = FontWeights.regular,
  subtitleFontWeight = FontWeights.regular,
  isUppercase,
  className,
  children,
  titlePreviewId,
  subtitlePreviewId,
}: PropsWithChildren<HeadlineProps>) => {
  const theme = useThemeName() || THEME_NAMES.DEFAULT;
  const themeValues = useTheme();
  const parsedTitle = Array.isArray(title) ? title : decodingContent(title);
  const parsedSubtitle = decodingContent(subtitle);
  const sizesSource = sizes[theme?.toLowerCase() ?? 'default'] || sizes.default;
  const weightClasses = {
    [FontWeights.bold]: styles.weightBold,
    [FontWeights.medium]: styles.weightMedium,
    [FontWeights.regular]: styles.weightRegular,
    [FontWeights.light]: styles.weightLight,
  };
  const finalTag = tag || format;
  const finalSize = sizesSource[format];

  if (finalTag === Formats.h1) {
    return (
      <Headline1
        title={parsedTitle}
        subtitle={parsedSubtitle}
        isUppercase={isUppercase || themeValues.headlines[finalTag].title.uppercase}
        size={finalSize}
        titleFontWeight={titleFontWeight}
        subtitleFontWeight={subtitleFontWeight}
        className={className}
        titlePreviewId={titlePreviewId}
        subtitlePreviewId={subtitlePreviewId}
      />
    );
  }

  if (!parsedTitle && !parsedSubtitle && isEmpty(children)) {
    return null;
  }

  return (
    <Typo
      className={buildClassName(
        styles.title,
        (isUppercase ||
          themeValues.headlines[finalTag === 'h4' ? 'h3' : finalTag].title.uppercase) &&
          styles.uppercase,
        className,
      )}
      tag={finalTag}
      fontWeight={titleFontWeight}
      size={finalSize}
    >
      {children}
      {Boolean(parsedTitle) && (
        <>
          <InlineEdit previewId={titlePreviewId}>{parsedTitle || 'placeholder'}</InlineEdit>
          {parsedTitle && parsedSubtitle ? ' ' : ''}
        </>
      )}
      {Boolean(parsedSubtitle) && (
        <span className={buildClassName(styles.subtitle, weightClasses[subtitleFontWeight])}>
          <InlineEdit previewId={subtitlePreviewId}>{parsedSubtitle}</InlineEdit>
        </span>
      )}
    </Typo>
  );
};
