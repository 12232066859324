import { useMemo, useCallback } from 'react';
import key from 'weak-key';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

// styles
import styles from './inner-html.module.scss';

// types
import type { InnerHtmlProps } from './inner-html.types';
import type { JSX } from 'react';

// components
import { CmsLink } from 'components/Link/CmsLink';
import { Icon } from 'components/Icon/Icon';
import { Headline as GDDSHeadline } from 'components/ContentElementsGdds/headline/headline';
import { FontWeights, Headline } from 'components/ContentElements/Headline';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { useUsercentrics } from 'features/cookie-consent';
import { replaceSharpS } from 'utils/replaceSharpS';
import { classNameBuilder as buildClassName } from 'utils/classNameBuilder';
import { useKolo } from 'utils/hooks/use-kolo';
import { isEmpty } from 'utils/is-empty';
import { useUsercentricsWorkaround } from 'features/cookie-consent/hooks/use-usercentrics-workaround';
import { useGroup } from 'utils/hooks/use-group';

export function InnerHtml({
  as: Element,
  content,
  isGdds = false,
  className,
  previewId,
  isSectionReference,
  gddsHeadlineTag,
  headlineTagsVariant,
  ...props
}: Readonly<InnerHtmlProps>) {
  const uc = useUsercentrics();
  const isKolo = useKolo();
  const isGroup = useGroup();
  const { setUcOpen } = useUsercentricsWorkaround();

  // replace a tag with Routerlink
  const transform = useCallback(
    (
      node: {
        type: string;
        name: keyof JSX.IntrinsicElements;
        attribs: Record<string, string>;
        children: any;
      },
      index: number,
    ) => {
      if (node.type === 'tag' && node.name === 'a') {
        const {
          attribs: {
            target,
            href,
            class: tagClassName,
            'data-local-medium': localMedium,
            targeturl,
            'data-download-size': downloadSize,
            'data-download-icon': downloadIcon,
          },
          children,
        } = node;

        if (tagClassName?.includes('_openUsercentricsLayer') || tagClassName?.includes('ucShow_')) {
          return (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                uc?.showModal();
                setUcOpen(true);
              }}
            >
              {children.pop()?.data}
            </a>
          );
        }

        let finalTarget = target;
        const isInternal =
          (href && !href.includes('http')) || localMedium ? 'internal_link' : 'external_link';

        if (href?.includes('javascript:')) {
          finalTarget = '_popup';
        }

        const link = {
          target: href,
          window: finalTarget || '',
          type: isInternal,
          localMedium,
          targeturl,
          showArrow:
            isGdds && downloadIcon !== 'download-pdf' && !tagClassName?.includes('noArrow'),
          download: downloadIcon || downloadSize ? true : undefined,
        };

        return (
          <span key={key(link)}>
            <CmsLink
              className={buildClassName(
                tagClassName,
                isGdds && downloadIcon === 'download-pdf' && styles.isDownload,
              )}
              link={link}
              key={key(link)}
              standardFontSize={false}
              inContext
              icon={isGdds && downloadIcon === 'download-pdf' ? 'Pdf' : undefined}
            >
              <span>
                {children?.[0]?.data}
                {downloadIcon && (
                  <>
                    &nbsp; (
                    {!isGdds && (
                      <>
                        <Icon symbol={downloadIcon} />
                        ,&nbsp;
                      </>
                    )}
                    {downloadSize?.trim()})
                  </>
                )}
              </span>
            </CmsLink>
          </span>
        );
      }
      if (isGdds && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7'].includes(node.name)) {
        if (isGroup) {
          return (
            <Headline
              key={key(node)}
              format={Formats[node.name]}
              isUppercase={false}
              titleFontWeight={isKolo && node.name !== 'h3' ? FontWeights.bold : undefined}
              subtitleFontWeight={isKolo && node.name !== 'h3' ? FontWeights.bold : undefined}
            >
              {node.children.map((childrenNode, childrenIndex) =>
                transform(childrenNode, childrenIndex),
              )}
            </Headline>
          );
        }

        return (
          <GDDSHeadline
            tag={gddsHeadlineTag || Formats[node.name]}
            variant={gddsHeadlineTag ? Formats[node.name] : undefined}
            isFlexItem
            className={styles.headlineWrapper}
            title={node.children.map((childrenNode, childrenIndex) =>
              transform(childrenNode, childrenIndex),
            )}
          />
        );
      }

      if (headlineTagsVariant?.[node.name]) {
        const Element = headlineTagsVariant[node.name];
        return (
          <Element className={node.name}>
            {node.children.map((childrenNode, childrenIndex) =>
              transform(childrenNode, childrenIndex),
            )}
          </Element>
        );
      }

      return convertNodeToElement(node, index, transform);
    },
    [uc, setUcOpen, isGdds, isKolo],
  );

  const JsxElement = Element as any;

  // generate react html
  const html = useMemo(() => {
    if (isEmpty(content)) {
      return null;
    }
    return ReactHtmlParser(String(content), {
      decodeEntities: true,
      transform,
    });
  }, [content, transform]);

  if (isEmpty(html)) return null;

  return (
    <JsxElement
      {...props}
      className={buildClassName(className, styles.displayLineBreak, isGdds && styles.isGdds)}
    >
      <InlineEdit previewId={previewId} isSectionReference={isSectionReference}>
        {replaceSharpS(html)}
      </InlineEdit>
    </JsxElement>
  );
}
