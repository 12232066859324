import React, { PropsWithChildren, type JSX } from 'react';
import { useIsPreview } from 'utils/hooks/useIsPreview';

interface InlineEditProps {
  previewId?: string;
  isSectionReference?: boolean;
  className?: string;
}

export function InlineEdit({
  previewId,
  children,
  isSectionReference = false,
  className,
}: PropsWithChildren<InlineEditProps>): JSX.Element {
  const isPreview = useIsPreview();

  if (isPreview && previewId && !isSectionReference) {
    return (
      <span data-preview-id={previewId} className={className}>
        {children}
      </span>
    );
  }

  return <span className={className}>{children}</span>;
}
