import { THEME_NAMES } from 'themes';
import type { PossibleThemeName } from 'utils/hooks/use-theme';
import { FontWeights } from './Headline';
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

/**
 * Get the FontWeight for a Headline
 *
 * @param emphasize Defines if the headline should be emphesized. This has no effect on NORD and KOLO themes!
 * @param theme The name of the Theme (should be the output of `useThemeName()`hook)
 * @param format The used format. Should reflect the value of `format={...}`
 * @param defaultWeight Usually we want headlines to be light if nothing other is specified. This can be overwritten here.
 * @returns The font-weight for the given usecase.
 */

export const getFontWeight = (
  emphasize: boolean,
  theme: PossibleThemeName,
  format: keyof typeof Formats,
  defaultWeight = FontWeights.light,
) => {

  // Headlines 1-3 should be light and 4-6 should always be bold on XY theme
  if (theme === THEME_NAMES.XY) {
    if (["h1","h2","h3"].includes(format)) {
      return FontWeights.light;
    }

    return FontWeights.bold;
  }

  // Headlines 1-3 should be regular and 4-6 should always be bold on NORD theme
  if (theme === THEME_NAMES.NORD) {
    if (["h1","h2","h3"].includes(format)) {
      return FontWeights.regular;
    }

    return FontWeights.bold;
  }

  // Headlines 1-2 should be bold and 3 should be regular
  if (theme === THEME_NAMES.KOLO) {
    if (["h1","h2"].includes(format)) {
      return FontWeights.bold;
    }

    if (["h3"].includes(format)) {
      return FontWeights.regular;
    }
  }

  // Headlines 1-3 should be light and 4-6 should be bold
  if (theme === THEME_NAMES.TWYFORD) {
    if (["h1","h2","h3"].includes(format)) {
      return FontWeights.light;
    }

    return FontWeights.bold;
  }

  if (emphasize) {
    return FontWeights.bold;
  }

  return defaultWeight;
};
